import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { Location } from '@mei/common/core/models/location';
import { User } from '@mei/common/core/models/user';
import { UserRole, UserRoleType } from '@mei/common/core/models/user-role';
import { LocationService } from '@mei/common/core/services/location.service';
import { NotificationService } from '@mei/common/core/services/notification.service';
import { PermissionApiService } from '@mei/common/core/services/permission-api.service';
import { PermissionService } from '@mei/common/core/services/permission.service';
import { UserService } from '@mei/common/core/services/user.service';
import { assertNonNull } from '@mei/common/core/utils/assert-non-null';
import { listenControlChanges } from '@mei/common/core/utils/rxjs/listen-control-changes';
import { LabelComponent } from '@mei/common/shared/components/label/label.component';
import { LoadingDirective } from '@mei/common/shared/directives/loading.directive';
import { BehaviorSubject, EMPTY, ignoreElements, merge, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { z } from 'zod';

import { DEFAULT_PAGINATION_FOR_ALL_RECORDS } from '@mei/common/core/models/base-filter-params';

import { UserCreationFormData, UserEditFormData } from './user-form.service';

/** User form component. */
@Component({
	selector: 'meiw-user-form',
	templateUrl: './user-form.component.html',
	styleUrl: './user-form.component.css',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		LabelComponent,
		ReactiveFormsModule,
		MatSelectModule,
		AsyncPipe,
		LoadingDirective,
		CommonModule,
	],
})
export class UserFormComponent implements OnInit {
	/** Form data. */
	@Input({ required: true })
	public form!: FormGroup<UserEditFormData> | FormGroup<UserCreationFormData>;

	/** User data. */
	@Input()
	public user: User | null = null;

	/** If the form is loading. */
	@Input()
	public isLoading: boolean | null = false;

	/** Form mode. */
	@Input()
	public mode: 'edit' | 'create' = 'edit';

	/** Submit event. */
	@Output()
	public readonly submitted = new EventEmitter();

	/** Cancel event. */
	@Output()
	public readonly cancelled = new EventEmitter();

	/** Edit permissions. */
	@Output()
	public readonly editPermissions = new EventEmitter();

	private readonly permissionApiService = inject(PermissionApiService);

	private readonly locationService = inject(LocationService);

	private readonly destroyRef = inject(DestroyRef);

	private readonly userService = inject(UserService);

	private readonly notificationService = inject(NotificationService);

	/** Permission service. */
	protected readonly permissionService = inject(PermissionService);

	/** All user roles. */
	protected readonly roles$: Observable<UserRole[]>;

	/** Locations. */
	protected readonly location$: Observable<Location[]>;

	/** All available locations. */
	protected readonly availableLocations$ = new BehaviorSubject<Location[]>([]);

	public constructor() {
		this.roles$ = this.permissionApiService.getRoles();
		this.location$ = this.locationService
			.getAll({ pagination: DEFAULT_PAGINATION_FOR_ALL_RECORDS })
			.pipe(shareReplay({ bufferSize: 1, refCount: true }));
	}

	/** @inheritdoc */
	public ngOnInit(): void {
		merge(
			this.updateSameLocationsSideEffect(),
		).pipe(
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe();
	}

	/**
	 * Handle "change" event of input type=file.
	 * @param eventTarget Event target.
	 */
	public onFileSelected(eventTarget: EventTarget | null): void {
		if (eventTarget instanceof HTMLInputElement && eventTarget.files?.[0]) {
			const file = eventTarget.files[0];
			this.form.controls.profilePicture.setValue(file);
			this.form.controls.displayPictureUrl.setValue(URL.createObjectURL(file));
			return;
		}
		throw new Error('Incorrect target for method handling');
	}

	private updateSameLocationsSideEffect(): Observable<void> {
		return listenControlChanges(this.form.controls.locationId, z.number().nullable()).pipe(
			switchMap(locationId => {
				if (this.user?.role == null || locationId == null) {
					return EMPTY;
				}
				if (this.user.role.type !== UserRoleType.Company && this.user.role.type !== UserRoleType.Region) {
					return EMPTY;
				}
				return this.locationService.getSameLocations(locationId);
			}),
			tap(locations => this.availableLocations$.next(locations)),
			ignoreElements(),
		);
	}

	/** Handle change password. */
	protected onChangePassword(): void {
		assertNonNull(this.user);
		this.userService.forgotPassword(this.user.email)
			.pipe(
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe({
				next: () => this.notificationService.notify('Please check your email for change password link!'),
			});
	}

	/** If the form should show advance settings (permission dialog). */
	protected shouldShowAdvanceSettings(): boolean {
		return this.form.controls.roleId.value === this.user?.role?.id || this.mode === 'create';
	}
}
